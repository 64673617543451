const axios = require("axios").default
const ENDPOINT = "https://api.ecart.chat"
/**
 * Post Leads to api server
 *
 * @param {*} { location, email }
 */

const collectLeads = async ({ location, email }) => {
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    throw new Error("Email is invalid")
  }
  await axios.post(`${ENDPOINT}/websites/collect-lead`, { location, email })
}

module.exports = { collectLeads }
