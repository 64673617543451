import React,{useState} from "react"

import Mail_icon_in_the_txt_field from "../assets/images/Mail_icon_in_the_txt_field.png"
import Right_arrow_white_in_the_txt_field from "../assets/images/Right_arrow_white_in_the_txt_field.png"
import F1_Messenger_icon from "../assets/images/F1_Messenger_icon.png"
import F5_bg_combined from "../assets/images/F5_bg_combined.png"
import email_retarget_step1 from "../assets/images/email_retarget_step1.png"
import email_retarget_step1_cropped from "../assets/images/email_retarget_step1_cropped.png"
import Left_upward_arrow from "../assets/images/Left_upward_arrow.png"
import Right_upward_arrow from "../assets/images/Right_upward_arrow.png"
import email_retarget_step2 from "../assets/images/email_retarget_step2.png"
import email_retarget_step3 from "../assets/images/email_retarget_step3.png"
import email_retarget_step3_cropped from "../assets/images/email_retarget_step3_cropped.png"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../api"

const EmailRetarget = () => {
  const [email, setEmail] = useState("");
  const [bEmail,setBEmail]=useState("");

  const handleLeads=async (position,emailId) => {
    try {
      const response=await api.collectLeads({
        location: position,
        email: emailId.trim(),
      })
      toast.success('Lead Collected',{
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (error) {
      toast.error(error.message,{
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  return (
    <Layout>
      <SEO />
      <ToastContainer />
      <header className="pb-3">
        <div className="container text-light">
          <div className="row">
            <div className="col-md-6 pt-3 email-retargeting-header">
              <h1>Email Retargeting</h1>
              <p>
                Customer followup mail template and sending address. Can Change
                any time
              </p>
              <p>
                <b>Want to know more?</b>
              </p>
              <form onSubmit={(e)=>e.preventDefault()}>
              <div className="input-group email-input-container mb-3 mx-auto position-relative">
                <input
                  type="text"
                  className="form-control mb-2"
                  id="header_email_input"
                  placeholder="Enter your mail id"
                  aria-label="Recipient's email"
                  aria-describedby="button-addon2"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <img
                  className="position-absolute mailIcon"
                  src={Mail_icon_in_the_txt_field}
                />
                <div className="input-group-append mb-2">
                  <button
                    className="btn btn-outline-secondary orangeBG"
                    type="submit"
                    id="button-addon2"
                    onClick={()=>handleLeads('Rretarget_top',email)}
                  >
                    <img src={Right_arrow_white_in_the_txt_field} />
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-primary d-inline text-light ml-sm-3 mx-auto mb-2 mt-sm-0"
                >
                  <img
                    className="messIcon"
                    src={F1_Messenger_icon}
                    width="15px"
                  />
                  &nbsp;Subscribe
                </button>
              </div>
              </form>
            </div>
            <div
              className="col-md-6 pt-5 text-sm-center"
              style={{ background: "none" }}
            >
              <div className="position-relative">
                <img
                  src={F5_bg_combined}
                  className="mw-100 w-sm-auto"
                  style={{
                    minHeight: 365,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container text-center p-3 p-sm-5 blueText">
        <h1 className="font-weight-normal">How it Works?</h1>
        <p className="mb-0">
          Send Abandoned Cart Remainder to Customer. Retarget Abandoned
          Customers and decrease cart abandoned rate and increase revenue.
        </p>
      </div>
      {/* Image Block Start */}
      <div className="container step-one position-relative">
        <div className="d-flex">
          <div className="col-lg-10 col-md-10 col-sm-10 col-12 mr-auto">
            <img
              src={email_retarget_step1}
              className="mw-100 d-none d-md-block"
            />
            <img
              src={email_retarget_step1_cropped}
              className="mw-100 d-md-none"
            />
            <div className=" card image-overlay-card rounded px-3 py-2 Small shadow">
              <div className="card-body  featureText position-relative">
                <p className="featureNumbers">1</p>
                <h4 className="font-weight-normal">Set Followup Timings</h4>
                <p className="descr">
                  Send Abandoned Cart Remainder to Customer. Retarget Abandoned
                  Customers and decrease cart abandoned rate
                </p>
                <div className="position-relative text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm  orangeBG ml-auto activate-widget"
                  >
                    Activate Now
                  </button>
                </div>
                <img
                  className="left-arrow d-none d-sm-block"
                  src={Left_upward_arrow}
                  width="65px"
                />
                <img
                  className="right-arrow d-sm-none"
                  src={Right_upward_arrow}
                  width="65px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container step-two position-relative product-second-step">
        <div className="d-flex">
          <div className="col-md-10 col-sm-12 col-12 ml-auto">
            <img
              src={email_retarget_step2}
              className="mw-100 d-none d-md-block float-right"
            />
            <img src={email_retarget_step2} className="mw-100 d-md-none" />
            <div className=" card image-overlay-card rounded px-3 py-2 Small shadow">
              <div className="card-body  featureText position-relative">
                <p className="featureNumbers">2</p>
                <h4 className="font-weight-normal">Choose Proper Template</h4>
                <p className="descr">
                  Send Abandoned Cart Remainder to Customer. Retarget Abandoned
                  Customers and decrease cart abandoned rate
                </p>
                <div className="position-relative text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm  orangeBG ml-auto activate-widget"
                  >
                    Make Changes
                  </button>
                </div>
                <img
                  className="right-arrow"
                  src={Right_upward_arrow}
                  width="65px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container step-one position-relative retarget-step-three">
        <div className="d-flex">
          <div className="col-lg-10 col-md-10 col-sm-10 col-12 mr-auto img-container">
            <img
              src={email_retarget_step3}
              className="mw-100 d-none d-md-block"
            />
            <img
              src={email_retarget_step3_cropped}
              className="mw-100 d-md-none"
            />
            <div className=" card image-overlay-card rounded px-3 py-2 Small shadow">
              <div className="card-body  featureText position-relative">
                <p className="featureNumbers">3</p>
                <h4 className="font-weight-normal">
                  Do Necessary Customization
                </h4>
                <p className="descr">
                  Send Abandoned Cart Remainder to Customer. Retarget Abandoned
                  Customers and decrease cart abandoned rate
                </p>
                <div className="position-relative text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm  orangeBG ml-auto activate-widget"
                  >
                    Activate Now
                  </button>
                </div>
                <img
                  className="left-arrow d-none d-sm-block"
                  src={Left_upward_arrow}
                  width="65px"
                />
                <img
                  className="right-arrow d-sm-none"
                  src={Right_upward_arrow}
                  width="65px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Image Block End */}
      <div id="integration" className="my-5 mx-auto text-center blueText">
        <h5 className="font-weight-normal py-3">
          Interested? Get a sample mail
        </h5>
        <form onSubmit={(e)=>e.preventDefault()}>
        <div className="position-relative getstarted py-3">
          <label
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            className="position-absolute aos-init aos-animate"
          >
            Your email id
          </label>
          <input
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            onChange={(e)=>setBEmail(e.target.value)}
            name
            placeholder="Eg:riyan1995@gmail.com"
            className="aos-init aos-animate"
            id="email_text_bottom"
          />
          <button
            type="submit"
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            className="btn btn-primary orangeBG ml-2 aos-init aos-animate email-retarget-btn"
            id="email_contact_bottom"
            onClick={()=>handleLeads('Retarget_bottom',bEmail)}
          >
            Try Now
          </button>
        </div>
        </form>
      </div>
    </Layout>
  )
}

export default EmailRetarget
